.services {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    background-color: #000000;
   
    .services-title {
        padding-bottom: 0;
    }
    .service-boxes {
        width: 80%;
        padding-top: 25px;
        .service-icon {
            position: unset;
            padding-bottom: 15px;
        }

        .service-info{
            align-content: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .service-info-title {
            height: 10px;
            margin-bottom: 3%;

            .service-name {
                font-size: 19px !important;
                font-weight: 400;
                color: #ffffff;
                opacity: 95%;
            }
        }

        .service-description {
            text-align: left !important;
            opacity: 70%;
            font-weight: 300 !important;
            font-size: 16px;
            padding-bottom: 40px;
            padding-top: 15px;
            line-height: 22px;
            color: rgba(255, 255, 255, 0.8);
        }

    }
    .title-text {
    opacity: 70%;
    font-weight: 300;
    font-size: 16px;
    }
}

.title-font-2 {
    font-family: 'Work Sans', sans-serif;
    line-height: 130%;
}

.section-title,
.section-text { text-align: left; }

.title-font {
    font-family: 'Work Sans', sans-serif;
    line-height: 130%;
}

.title-font-size {
    color:#ffffff;
    font-size: 30px;
    font-weight: 400;
    opacity: 95%;
}

.section-title {
    padding-top: 0%;
    padding-bottom: 10%;
    margin-bottom: 70px;
}

.row {
    margin-right: 0;
    margin-left: 0;
}

.service-icon-img{
    height: 50px !important;
    width: 50px !important;
}

@media screen and (max-width: 769px) {
    .section-title, .section-text {
        text-align: center !important;
    }

    .services {
        .service-boxes {
            width: 100%;
    
            .service-description {
                text-align: center !important;
                padding-bottom: 35px;
                font-size: 14px;
                width: 60%;
            }
    
        }
    }

    .title-font-size {
        color: #FED73C;
    }
}

@media screen and (max-width: 586px){
    .services {
        .service-boxes {
    
            .service-description {
                width: 100%;
            }
    
        }
    }

}

@media screen and (max-width: 429px) {
    .section-title, .section-text {
        text-align: center !important;
    }

    .services {
        .service-boxes {
            width: 100%;
            .service-info-title {
                .service-name {
                    font-size: 16px !important;
                }
            }
    
            .service-description {
                text-align: center !important;
                font-size: 12px;
                padding-bottom: 15px;
                line-height: 18px;
            }
    
        }
        .title-text {
            font-size: 14px;
        }
    }
}


@media screen and (max-width: 280px) {
    .services {
        .service-boxes {
            .service-info-title {
                .service-name {
                    font-size: 14px !important;
                }
            }
    
            .service-description {
                line-height: 18px;
            }
        }
    }

    & .service-name {
    line-height: 15px;
    }
}