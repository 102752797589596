.text-logo {
  color: #fed73c;
  font-size: 25px;
  font-weight: 500;
  text-transform: uppercase;
}

.nav-links {
  list-style: none;
  display: flex;
  font-size: 15px !important;
  font-weight:300 !important;
}

.nav-links li {
  margin: 0 10px;
}

.nav-links li a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.7);
  transition: color;
  cursor: pointer;
  margin-bottom: 0% !important;
}

.nav-links .active-link{
  color: #ffffff;
}

.nav-links li a:hover {
  color:#ffffff;
}

img{
  height: 30px !important;
  width: 30px !important;
  }
  
  .wrapper-next-nav{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 60px !important;
    padding: 15px;
    width: 100%;
    height: 80px;
    margin: 0;
    align-items: center;
    }

    .logo_bar{
      display: flex;
      flex-direction: row;
      gap:12px;
      align-items: center;
    }

    @media screen and (max-width: 550px) {
  
      .text-logo {
        font-size: 28px;
      }
      img{
        height: 35px !important;
        width:35px !important;
      }
      
      }

   
    @media screen and (max-width: 430px) {
  
      .nav-links {
        list-style: none;
        display: flex;
        font-size: 12px !important;
        font-weight:300 !important;
        display: none;
      }
      
      }


    @media screen and (max-width: 369px) {

    .text-logo {
      color: #fed73c;
      font-size: 22px;
      font-weight: 600;
      text-transform: uppercase;
      padding-right: 77px;
    }

    .nav-links {
      list-style: none;
      display: flex;
      font-size: 12px !important;
      font-weight:300 !important;
      display: none;
    }
    
    }

    @media screen and (max-width: 330px) {

      .text-logo {
        padding-right: 50px;
      }
      
      }

      @media screen and (max-width: 259px) {

        .text-logo {
          padding-right: 10px;
        }
        
        }