

body {
  margin: 0;
  font-family: 'Work Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Work Sans', sans-serif;
}

html {
  font-size: 16px;
  font-family: 'Work Sans', sans-serif;
}

body {
  background-color: rgb(5, 5, 5);
  color: white;
}

:focus-visible { outline-offset: 4px; }

::-webkit-scrollbar { width: 15px; }

::-webkit-scrollbar-track { background-color:  hsl(0, 0%, 25%);}

::-webkit-scrollbar-thumb { background-color: hsl(0, 0%, 44%); }

::-webkit-scrollbar-thumb:hover { background-color: hsl(0, 0%, 54%);}

* {
  padding: 0;
}