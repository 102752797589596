.home_class{
padding-bottom: 100px;
}

.Hero {
    color: rgb(255, 255, 255);
    margin-top: 60px;
    height: auto;
}
.title-font{
    font-family: 'Work Sans', sans-serif;
    color:#ffffff;
    font-size: 45px;
    font-weight: 500;
}

.title-font3{
    /* 500 */
    margin: 20px 100px 30px 0px;
    color:white;
    opacity: 70%;
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
}

.Hero-row {
    display: flex;
    width: 100%;
    padding-top: 0px;
    margin: 0%;
    align-items: center;
    gap: 0px;
    flex-direction: row;
}


.giff_class{
height: 500px !important;
width: 450px !important;
align-items: flex-start !important;
}

.Hero-image-mobile {
    display: none;
}

.Hero-image {
    width: 130%;
    height: 130%;
    opacity: 10%;
}

.Hero-text {
    margin-bottom: 0px;
}

.Hero-text h1 {
    /* 45 */
    font-size: 35px;
    color: white;
    font-weight: 300;
    opacity: 95%;
    width: 100%
}
.Hero-text h4 {
    font-size: 16px;
    font-weight: 300;
    color: #FED73C;
    padding-left: 0.3%;
    text-transform: uppercase;
}

.text-customer {
    color:#ffffff;
    /* 55 */
    font-size: 40px;
    font-weight: 500;
    display: contents;
}

.on {
    display: inline-block;
    border-radius: 250px;
    color: #ffffff;
    font-family: 'Work Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    width: fit-content;
    padding: 9px 23px;
    border: 2px solid #ffffff;
    text-align: center;
  }
  

  .on-submit:is(:hover, :focus) {
    color: #FED73C;
    border-color: #FED73C;
  }
 
  .lead-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../../images/home/homelogo.png");
    background-size: 380px;
    background-repeat: repeat-x repeat-y;
    background-position: top;
    opacity: 0.03;
    filter: grayscale(80%);
    z-index: -1;
    height: 660px;
  }


  @media screen and (max-width: 1200px) {

    .title-font3{
        margin: 20px 40px 30px 0px;
    }
    
.Hero-text h1 {
    font-size: 32px;
    width: 105%
}

.text-customer {
    color:#ffffff;
    /* 55 */
    font-size: 37px;
}

.giff_class{
    height: 400px !important;
    width: 350px !important;
    }
  }

  @media screen and (max-width: 992px) {

    .title-font3{
        margin: 20px 30px 30px 0px;
        
    font-weight: 300;
    font-size: 13px;
    line-height: 22px;
    }
    
.Hero-text h1 {
    font-size: 23px;
    width: 105%
}

.text-customer {
    color:#ffffff;
    /* 55 */
    font-size: 27px;
}

.giff_class{
    height: 360px !important;
    width: 310px !important;
    }
  }

  @media screen and (max-width: 768px) {

    .title-font3{
    margin: 0px;
    font-weight: 300;
    font-size: 13px;
    line-height: 22px;
    width: 75%;
    padding-bottom: 30px;
    text-align: center;
    }
    
.Hero-text h1 {
    font-size: 34px;
    width: 80%;
    text-align: center;
    padding-bottom: 10px;
}

.text-customer {
    color:#ffffff;
    /* 55 */
    font-size: 39px;
}

.giff_class{
    height: 400px !important;
    width: 350px !important;
    padding-bottom: 40px;

    }

    .Hero-text{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .Hero-row{
        flex-direction: column-reverse;
    }
  }

  @media screen and (max-width: 768px) {

    .title-font3{
    margin: 0px;
    font-weight: 300;
    font-size: 13px;
    line-height: 22px;
    width: 59%;
    padding-bottom: 30px;
    text-align: center;
    }
    
.Hero-text h1 {
    font-size: 24px;
    width: 70%;
    text-align: center;
    padding-bottom: 10px;
}

.text-customer {
    color:#ffffff;
    /* 55 */
    font-size: 29px;
}
  }

  @media screen and (max-width: 618px) {

    .title-font3{
    margin: 0px;
    font-weight: 300;
    font-size: 13px;
    line-height: 22px;
    width: 80%;
    padding-bottom: 30px;
    text-align: center;
    }
    
.Hero-text h1 {
    font-size: 24px;
    width: 80%;
    text-align: center;
    padding-bottom: 10px;
}

.text-customer {
    color:#ffffff;
    /* 55 */
    font-size: 29px;
}
  }

  @media screen and (max-width: 489px) {

    .title-font3{
    margin: 0px;
    font-weight: 300;
    font-size: 13px;
    line-height: 22px;
    width: 80%;
    padding-bottom: 30px;
    text-align: center;
    }
    
.Hero-text h1 {
    font-size: 24px;
    width: 90%;
    text-align: center;
    padding-bottom: 10px;
}

.text-customer {
    color:#ffffff;
    /* 55 */
    font-size: 29px;
}

.giff_class{
    height: 350px !important;
    width: 300px !important;
    padding-bottom: 40px;

    }
  }

  @media screen and (max-width: 455px) {

    .title-font3{
    margin: 0px;
    font-size: 12px;
    line-height: 19px;
    width: 100%;
    padding-bottom: 30px;
    text-align: center;
    }
    
.Hero-text h1 {
    font-size: 20px;
    width: 90%;
    font-weight: 500;
    text-align: center;
    padding-bottom: 10px;
}

.text-customer {
    color:#ffffff;
    /* 55 */
    font-size: 20px;
    font-weight: 500;
}

.giff_class{
    height: 350px !important;
    width: 300px !important;
    padding-bottom: 40px;

    }
  }

  @media screen and (max-width: 430px) {
  .wrapper-next-nav {
    flex-direction: column;
}

.text-logo {
    font-size: 28px;
  }
}

  @media screen and (max-width: 350px) {

    .wrapper-next-nav {
        flex-direction: column;
    }

    .title-font3{
    margin: 0px;
    font-size: 11px;
    line-height: 18px;
    width: 90%;
    padding-bottom: 30px;
    text-align: center;
    }
    
.Hero-text h1 {
    font-size: 18px;
    width: 90%;
    font-weight: 500;
    text-align: center;
    padding-bottom: 0px;
    margin-top: 10px;
}

.text-customer {
    color:#ffffff;
    /* 55 */
    font-size: 18px;
    font-weight: 500;
}

.giff_class{
    height: 380px !important;
    width: 360px !important;
    padding-bottom: 20px;
    }

    .Hero-text h4 {
        font-size: 13px;
    }
  }