li { list-style: none; }

a {
  text-decoration: none;
  color: inherit;
  margin-bottom: 10px;
}

a,
span,
input,
strong,
button,
textarea,
ion-icon 
{   
  display: block; 
  font-family: 'Work Sans', sans-serif;
  text-decoration: none !important;
}

ion-icon {
  pointer-events: none;
  color: white;
}

button,
input,
textarea {
  background: none;
  border: none;
  font: inherit;
  font-family: 'Work Sans', sans-serif;
}

button { cursor: pointer; }

input,
textarea { 
  width: 100%;
  font-family: 'Work Sans', sans-serif; 
}

address { font-style: normal; }

.contact-card {
    padding: 50px;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
}

.contact-card .card-subtitle {
    color:#ffffff;
    font-size: 30px;
    font-weight: 400;
    opacity: 95%;
}

.contact .section-title {
    text-align: left;
    margin-block-end: 30px;
}

.contact-form { 
    margin-block-end: 50px;
    width: 630px;
    display: flex;
    flex-direction: column;
    color: rgba(255, 255, 255);
}

.contact-input {
    color: rgba(255, 255, 255);
    padding: 15px 25px;
    font-size: 15px;
    font-weight: 300;
    margin-block-end: 15px;
    opacity: 100%;
    border: none;
    border-color: #ffffff;
    border-bottom: 1px solid rgb(255, 255, 255);
}

.contact-input::label { color: white; }

textarea.contact-input {
    resize: vertical;
    min-height: 50px;
    height: 10px;
    max-height: 300px;
    font-family: 'Work Sans', sans-serif;
    height: 113px;
}

.contact-item-title {
    color: rgba(255, 255, 255, 0.4);
    font-family: 'Work Sans', sans-serif;
    font-size: 12px;
    font-weight: 100;
    text-transform: uppercase;
    margin-top: 0px;
    text-decoration: none;
}

.contact-item-link { 
    transition: 0.05s ease; 
}

.contact-item-link:not(address):is(:hover, :focus) { color:#ffffff; }

.contact-card { padding: 60px 15px 0px 15px; }

.contact .wrapper {
    display: flex;
    justify-content: space-between;
    font-family: 'Work Sans', sans-serif;
    flex-direction: column;
}

.contact-form { margin-block-end: 0; }

.section { padding-block: 0px; }

.section-subtitle {
    color: #fed73c;
    font-family: 'Work Sans', sans-serif;
    font-size: 12px;
    text-align: center;
}

.section-title,
.section-text { text-align: left; }

.h2 { 
    opacity: 70%;
    font-weight: 300;
    font-size: 16px;
    width: 60%;
}

.section-title {
    padding-top: 0%;
    padding-bottom: 30px !important;
}

.contact-list {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    gap: 3px;
    color: rgba(255, 255, 255, 0.7);
    font-size: 15px !important;
    font-weight: 300 !important;
}

.social-icons {
    display: flex;
    align-items: center;
    gap: 20px;
}

.social-icons a {
    color: rgba(255, 255, 255, 0.4);
    font-size: 20px;
}

.social-icons :is(:hover, :focus) {
    color: #ffffff;
}

.img-contact {
    height: 100px !important;
    width: 100px !important;
}

.background {
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 65px;
    background-color: black;
}

.wrapper-next {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 0px;
    padding-left: 15px;
    padding-right: 15px;
    align-content: center;
    flex-wrap: wrap;
}

ul {
    margin-bottom: 0px !important;
}

.rights {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.rights p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 15px !important;
    font-weight: 300 !important;
}

.alignlogo {
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.copyright {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.rowflex {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
}

.rowflex img {
    height: 500px !important;
    width: 400px !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #ffffff !important;
}

.MuiFormLabel-root {
    color: rgba(255, 255, 255, 0.2) !important;
    font-size: 15px !important;
    font-weight: 300 !important;
}

.MuiFormLabel-root.Mui-focused {
    color: #ffffff !important;
}

.MuiFilledInput-underline:after {
    border-bottom-color: #ffffff !important;
}

.react-tel-input .form-control {
    padding-top: 15.5px;
    padding-bottom: 15.5px;
    border: 1px solid #ffffff !important;
    transition: none !important;
}

.react-tel-input .form-control:hover {
    border-color: #fefefe !important;
}

.react-tel-input .form-control:focus {
    border-color: #ffffff !important;
    box-shadow: 0 0 0 1px #efefef!important;
}

.message-field::before {
    border-bottom: none !important;
}

.contact-form-section-two-field {
    margin-bottom: 2rem !important;
    transition: 0.5s all cubic-bezier(.67,-0.73,.34,1.67) !important;
}

.contact-form-section-one {
    display: flex !important;
    width: 100%;
    margin-bottom: 2rem;
    transition: 0.5s all cubic-bezier(.67,-0.73,.34,1.67) !important;
    flex-direction: column;
    gap: 20px;
}

.contact-form-section-one-field {
    margin: 0 1rem 0rem 0rem !important;
    transition: 0.5s all cubic-bezier(.67,-0.73,.34,1.67) !important;
}

.contact-form-section-one-field:nth-child(2) {
    margin: 0 0rem 0rem 1rem !important;
    transition: 0.5s all cubic-bezier(.67,-0.73,.34,1.67) !important;
}

.Mui-error {
    font-family: 'Work Sans', sans-serif !important;
    letter-spacing: 0 !important;
}

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
    color: rgb(244 244 244 / 70%) !important;
    font-family: 'Work Sans', sans-serif !important;
    font-weight: 300;
    letter-spacing: 10px;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: rgb(255 255 255 / 20%) !important;
}

.css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
    color: rgb(244 244 244 / 70%) !important;
    font-family: 'Work Sans', sans-serif !important;
    font-weight: 300;
    letter-spacing: 10px;
}

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
    color: rgb(244 244 244 / 70%) !important;
    font-family: 'Work Sans', sans-serif !important;
    font-weight: 300;
    letter-spacing: 10px;
}
.on.on-submit:disabled {
  cursor: not-allowed;
  border-color: #d32f2f;
  color: #d32f2f;
}

.horizontal-line {
    border: none;
    height: 1px;
    background-color: #ffffff;
    margin: 10px 0;
    transform: scaleY(0.5);
  }
  
  .hr_padding{
    padding-bottom: 40px;
  }

  /* Media Query-------------------------- */

  
  @media screen and (max-width: 640px) {

    .rights {
        align-items: center;
        gap: 30px;
    }
    .text-logo {
        padding-right: 0px;
  }
  .rights p{
    text-align: center !important;
  }
  .alignlogo {
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
}
.copyright {
    align-items: center;
}
.contact-list {
    align-items: center;
}
  
.wrapper-next {
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
}
.container {
    align-items: center;
}

}
